import React from 'react'
import injectSheet from 'react-jss';
import Navigation from "./Navigation";
import Logo from "./Logo";

import createResponsiveStyles from "../../utils/createResponsiveStyles";



const styles = theme => {
    const headerContent = createResponsiveStyles('headerContent', theme);
    headerContent.headerContent.height = '100%';
    headerContent.headerContent.position = 'relative';
    headerContent.headerContent.zIndex = 1200;

    return ({
        root: {
            position: 'fixed',
            zIndex: 1200,
            height: '4.125rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            top: 0,
            left: 0,
            borderBottomWidth: 2,
            borderBottomStyle: 'solid',
            borderColor: 'transparent',
            '&.solid': {
                borderColor: theme.palette.primary.dark,
                '&::after': {
                    opacity: 1,
                    display: 'block',
                }
            },
            transition: 'transform .25s ease-in',
            '&.hide': {
                transform: 'translateY(-100%)',
            },
            '&::after': {
                content: '\'\'',
                display: 'block',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                background: theme.palette.background,
                opacity: 0.4,
                [theme.breakpoints.down('xs')]:{
                    display: 'none',
                }
            },
        },
        ...headerContent
    });
};

const Header = ({siteTitle, classes, solid, hide}) => (
    <div className={classes.root + (solid? ' solid': '') + (hide? ' hide': '')} >
        <Logo />
        <div className={classes.headerContent} >
            <Navigation siteTitle={siteTitle}/>
        </div>
    </div>
);

export default injectSheet(styles)(Header);
