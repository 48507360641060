import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHome} from '@fortawesome/free-solid-svg-icons/faHome';
import {faFacebookF} from '@fortawesome/free-brands-svg-icons/faFacebookF';
import {faTwitter} from "@fortawesome/free-brands-svg-icons/faTwitter";
import {faInstagram} from "@fortawesome/free-brands-svg-icons/faInstagram";
import {faCircle} from "@fortawesome/free-solid-svg-icons/faCircle";

export const MediaType = {
    WEBSITE: 'website',
    FACEBOOK: 'facebook',
    TWITTER: 'twitter',
    INSTAGRAM: 'instagram',
};

const getIcon = (type) => {
    switch(type){
        case MediaType.FACEBOOK:
            return faFacebookF;
        case MediaType.TWITTER:
            return faTwitter;
        case MediaType.INSTAGRAM:
            return faInstagram;
        case MediaType.WEBSITE:
        default:
            return faHome;
    }
};

const SocialMediaIcon = ({href, type}) => {
    const icon = getIcon(type);
    return (
        <a href={href} className={'touchable'} target="_blank" rel="noopener">
        <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon={faCircle}/>
            <FontAwesomeIcon icon={icon} transform={{size: 10}} className={'invert'}/>
        </span>
        </a>
    );
};

export default SocialMediaIcon;
