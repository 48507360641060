import React from 'react'
import injectSheet from 'react-jss';
import {Link} from 'gatsby'
import SocialMediaIcon, {MediaType} from "../SocialMediaIcon";

const styles = containerColumns => theme => {
    const keys = Object.keys(containerColumns);
    const containerColumnsMax = keys.length+1;
    const firstKey = keys[0];

    const marginRight = 24;
    let mediaQuery = '&:nth-child('+containerColumnsMax+'n)';
    const value = {
        article: {
            marginRight,
            paddingBottom: 24,
            flex: '0 0 auto',

            width: 'calc((100% - ' + (marginRight*(containerColumnsMax-1)) +'px) / '+containerColumnsMax+')',
            display: 'flex',
            flexDirection: 'column',

            '&:last-child > div::after': {
                borderLeft: 0,
            },
            [theme.breakpoints.up(firstKey)]: {
                [mediaQuery]: {
                    marginRight: 0,
                    '& > div::after':{
                        borderLeft: 0,
                    },
                },
            },
            transition: 'color .5s',
            '&.colorize:hover': {
//                background: 'rgba(183,183,183,0.3)',
                color: 'rgba(183,183,183,1)',
//                color: 'rgb(211,255,254)',
                '& $content': {
                },
                '& $imageOverlay': {
                    background: 'rgba(183,183,183,0.3)',
//                    background: 'rgba(211,255,254,0.3)',
                },
                '& $image > div': {
                    transform: 'scale(1.1)',
                },
            },
        },
        date: {
            fontSize: '1rem',
            fontWight: 400,
            padding: '0.25rem 0.5rem 0 0',
            textTransform: 'uppercase',
            },
        header: {
            position: 'relative',
            width: '100%',
            paddingTop: 900/16+'%',
            '& $date': {
                position: 'absolute',
                bottom: 0,
                zIndex: 10,
                background: theme.palette.background,
            }
        },
        image: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            overflow: 'hidden',
            '& > div':{
                transition: 'transform .25s ease-in',
            },
        },
        imageOverlay:{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 5,
            transition: 'background .5s',
            borderRadius: 4,
//            background: 'rgba(61,183,162,0.3)',
        },
        wrappedContent: {
            position: 'relative',
                flex: '1 1 auto',
                display: 'flex',
                flexDirection: 'column',
                '&::after': {
                    content: "''",
                    zIndex: 1,
                    position: 'absolute',
                    right: -12,
                    top: 0,
                    bottom: 0,
                    borderLeft: '1px solid' + theme.palette.primary.dark,
            },
        },
        content: {
            transition: 'color .5s',
            flex: '1 1 auto',
            paddingTop: 6,
            paddingRight: 6,
            paddingLeft: 6,
        },
        footer: {
            flex: '0 0 auto',
            paddingRight: 6,
            paddingLeft: 6,
            display: 'flex',
            justifyContent: 'space-between',
        },
        button: {
            '& a': {
                margin: 0,
                marginTop: '.8em',
            },
        },
        socialMedia: {
            listStyle: 'none',
            display: 'flex',
            alignItems: 'center',
            margin: 0,
            '& li': {
                margin: 0,
            }
        }
    };
    for(let key in containerColumns) {
        if(containerColumns.hasOwnProperty(key)) {
            let breakpoint = theme.breakpoints.down(key);
            if(!value[breakpoint]) value[breakpoint] = {};
            value[breakpoint] = {
                'content': {
                    '& > article': {
                    }
                }
            };
        }
    }
    let last=null;
    for(let key in containerColumns) {
        if(containerColumns.hasOwnProperty(key)) {
            if(last!==null){
                let breakpoint = theme.breakpoints.between(key, last);
                if(!value[breakpoint]) value[breakpoint] = {};
                mediaQuery = '&:nth-child(' + containerColumns[last] + 'n)';
                value[breakpoint] = {
                    article: {
                        flex: '0 0 auto',
                        width: 'calc((100% - ' + (marginRight*containerColumns[last]) +'px) / '+containerColumns[last]+')',
                        '& > div':{

                        },
                        [mediaQuery]: {
                            marginRight: 0,
                            '& > div::after': {
                                borderLeft: 0,
                            }
                        }
                    }
                };
            }
            last=key;
        }
    }
    let breakpoint = theme.breakpoints.down(last);
    if(!value[breakpoint]) value[breakpoint] = {};
    mediaQuery = '&:nth-child(' + containerColumns[last] + 'n)';
    value[breakpoint] = {
        article: {
            flex: '0 0 auto',
            width: 'calc((100% - ' + (marginRight*containerColumns[last]) +'px) / '+containerColumns[last]+')',
            '& > div':{

            },
            [mediaQuery]: {

                '& > div::after': {
                    borderLeft: 0,
                }
            }
        }
    };
    return value;
};

const OverviewContent = ({classes, img, children, website, facebook, instagram, to, date, colorize=true}) => {
    if(img){
        let imageOverlay = colorize? (<span className={classes.imageOverlay}></span>): null;
        if(to){
            img = (<Link to={to} className={classes.image}>{imageOverlay}{img}</Link>);
        } else {
            img = (<span className={classes.image}>{imageOverlay}{img}</span>);
        }
    }
    let more = to? (<Link to={to} className={'button'}>Weiter</Link>): null;
    let socialMedia = [];
    if(website){
        socialMedia.push(<li><SocialMediaIcon href={website} type={MediaType.WEBSITE}/></li>);
    }
    if(facebook){
        socialMedia.push(<li><SocialMediaIcon href={facebook} type={MediaType.FACEBOOK}/></li>);
    }
    if(instagram){
        socialMedia.push(<li><SocialMediaIcon href={instagram} type={MediaType.INSTAGRAM}/></li>);
    }
    socialMedia = socialMedia.length>0? (<ul className={classes.socialMedia}>{socialMedia}</ul>): null;
    if(date){
        date = (<div className={classes.date}>{date}</div>);
    }
    return (
        <article className={classes.article + (colorize? ' colorize': '')}>
            <header>
                <div className={img? classes.header: ''}>
                {date}
                {img}
                </div>
            </header>
            <div className={classes.wrappedContent}>
                <div className={classes.content}>
                    {children}
                </div>
                <footer className={classes.footer}>
                    <div className={classes.button}>{more}</div>
                    {socialMedia}
                </footer>
            </div>
        </article>
    );
};

export const SmallOverviewContainer = injectSheet(styles({xs: 1}))(OverviewContent);

export default injectSheet(styles({
    xs: 2,
    xxs: 1
}))(OverviewContent);