import React from "react";
import injectSheet from 'react-jss';
import {Link} from 'gatsby';

import borduere from '../../assets/carousel/Slam2018_Artwork_Web-Karussell_Borduere.png';

const styles = theme => {
//    let color = theme.palette.primary.dark;
    let color = theme.palette.backgroundSecondary;
    return {
        borduereWrapper: {
            position: 'absolute',
            left: "15vw",
            top: '-10%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        borduere: {
            flex: '1 1 auto',
            height: '80%',
            objectFit: 'contain',
            objectPosition: 'left',
            transform: "translateY(-100%)",
            transition: 'transform .75s ease-in',
            '&.selected': {
                transform: 'translateY(0)',
            },
        },
        text: {
            alignSelf: 'flex-start',
            transform: "translateX(-200%)",
            transition: 'transform .5s ease-in .15s',
            '& > *': {
                color: color,
                fontWeight: 'bolder',
            },
            '&.selected': {
                transform: 'translateX(0)',
            },
        },
        ticket: {
            fontSize: '.75em',
            [theme.breakpoints.down('xxs')]: {
                display: 'none',
            },
            position: 'absolute',
            right: '15%',
            bottom: '15%',
            opacity: 1,
            transition: 'opacity .5s ease-in',
            '&.hide': {
                opacity: 0,
            },
            '& a.button':{
                fontSize: '1.3em',
                lineHeight: 1,
                color: color,
                borderColor: color,
                background: theme.palette.background,
                borderWidth: '.1em',
                borderRadius: 4,
                textAlign: 'left',
                '&:hover': {
                    color: theme.palette.background,
                    background: color,
                },
            }
        },
    }
};

const SliderContent = ({active, hide=false, classes}) => {
    let additionalClassname = (hide? ' hide': (active? ' selected': ''));
    return (
        <>
            <div className={classes.borduereWrapper}>
                    <img className={classes.borduere + additionalClassname} src={borduere}/>
                <div className={classes.text + additionalClassname}>
                    <h4>Vom 6. - 10. November<br/>2018 in Zürich</h4>
                </div>
            </div>
            <div className={classes.ticket + additionalClassname}>
                <Link to={'/tickets'} className="button">Jetzt Ticket<br/>sichern</Link>
            </div>
        </>
    );
};

export default injectSheet(styles)(SliderContent);