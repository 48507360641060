import React from 'react'
import injectSheet from 'react-jss';
import { Link } from "gatsby"

const styles = theme => {
    return {
        root: {
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            padding: '12px 24px',

            '&.invert': {
                flexDirection: 'row',
                background: theme.palette.primary.dark,
                color: theme.palette.background,
                [theme.breakpoints.between('md','xxl')]: {
                    flexDirection: 'column',
                },
                [theme.breakpoints.down('md')]: {
                    fontSize: '1em',
                },
                [theme.breakpoints.down('xxs')]: {
                    flexDirection: 'column',
                },
                '& $text': {
//                    fontSize: '1.5em',
                    lineHeight: '1.1em',
                    borderRight: '2px solid ' + theme.palette.background,
                    marginRight: '1em',
                    paddingRight: '1em',
                    width: 'calc(100% - 7.25em)',
                    fontWeight: 600,
                    [theme.breakpoints.between('md','xxl')]: {
                        borderRight: 0,
                        marginRight: 0,
                        paddingRight: 0,
                        borderBottom: '2px solid ' + theme.palette.background,
                        marginBottom: '1em',
                        paddingBottom: '1em',
                        width: 'auto',
                    },
                    [theme.breakpoints.down('xxs')]: {
                        borderRight: 0,
                        marginRight: 0,
                        paddingRight: 0,
                        borderBottom: '2px solid ' + theme.palette.background,
                        marginBottom: '1em',
                        paddingBottom: '1em',
                        width: 'auto',
                    },
                },
                '& $control': {
                    lineHeight: '1.2em',
                    marginTop: 0,
                    alignSelf: 'center',
                    '& > .button': {
                        borderWidth: '2px',
                        background: theme.palette.background,
                        width: '7em',
                        '&:hover': {
                            background: theme.palette.secondary.dark,
                            color: theme.palette.secondary.light,
                        },
                    }
                },
            },
        },
        text:{

        },
        control:{
            marginTop: '1em',
            alignSelf: 'flex-start',
            '& > .button':{

            }
        },
    };
};

const BoxContent = ({children, linkTo, linkText, invert=false, classes}) => {
    return (
        <div className={classes.root + (invert? ' invert': '')}>
            <h4 className={classes.text}>
                {children}
            </h4>
            <div className={classes.control}>
                <Link to={linkTo} className="button">{linkText}</Link>
            </div>
        </div>
    );
};

export default injectSheet(styles)(BoxContent);