import React from "react";
import injectSheet from 'react-jss';

import MyCarousel from "../MyCarousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import logo from '../../assets/logo/slam2018_tuerkis_nob.svg';

const styles = theme => {
//    let color = 'transparent';
//    color = theme.palette.primary.dark;
    let radius = 6;
//    let color = theme.palette.primary.dark;
    let color = theme.palette.backgroundSecondary;
    return {
        '@global':{
            '.carousel.carousel-slider': {
                overflow: 'visible',
            },
            '.carousel .control-dots': {
                zIndex: 10,
                ['@media (max-height: 420px)']: {
                    bottom: '-2rem',
                },
                [theme.breakpoints.down('xxs')]: {
                    bottom: '-2rem',
                },
            },
            '.carousel .control-dots .dot': {
                boxShadow: 'none',
                opacity: 1,
                width: radius*2,
                height: radius*2,
                border: '2px solid ' + color,
                background: color,
                '&:hover, &.selected':{
                    background: 'transparent',
                },
            },
        },
        root: {
            position: 'relative',
            '& *': {
                transition: 'font-size .5s ease-in',
            },
            '& .carousel .slide iframe': {
                margin: 0,
                width: '100%',
            },
        },
        logo: {
            position: 'absolute',
            bottom: '10%',
            right: '50%',
            '& img': {
                height: '8em',
                marginLeft: '50%',
            },

            opacity: 1,
            transition: 'opacity .25s ease',
            '&.hide': {
                opacity: 0,
            },


            fontSize: '1rem',
            [theme.breakpoints.up('lg')]:{
                ['@media (max-height: 650px)']: {
                    display: 'none',
                }
            },
            [theme.breakpoints.between('md', 'lg')]:{
                ['@media (max-height: 600px)']: {
                    display: 'none',
                }
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '.75rem',
            },
            [theme.breakpoints.between('sm', 'md')]:{
                ['@media (max-height: 570px)']: {
                    display: 'none',
                }
            },
            [theme.breakpoints.down('sm')]:{
                fontSize: '.5rem',
            },
            [theme.breakpoints.between('xs', 'sm')]:{
                ['@media (max-height: 480px)']: {
                    display: 'none',
                }
            },
            [theme.breakpoints.down('xs')]:{
                fontSize: '.25rem',
                ['@media (max-height: 370px)']: {
                    display: 'none',
                }
            },
            [theme.breakpoints.down('xxs')]:{
                display: 'none',
            },
        }
    }
};

const ResponsiveSlider = ({autoPlay, showIndicators, onChange, selectedItem, children, classes}) => {
    return (
        <div className={classes.root}>
            <MyCarousel
                autoPlay={autoPlay}
                stopOnHover={autoPlay}
                infiniteLoop
                emulateTouch
                showIndicators={showIndicators}
                showThumbs={false}
                showArrows={false}
                showStatus={false}
                onChange={onChange}
                selectedItem={selectedItem}
                interval={6000}
            >
                {children}
            </MyCarousel>

            <div  className={classes.logo + (!showIndicators? ' hide': '')}>
                <img src={logo}/>
            </div>
        </div>
    );
};

export default injectSheet(styles)(ResponsiveSlider);