import React from "react";
import injectSheet from 'react-jss';

const styles = theme => {
    let radius = 6;
    return {
        '@global': {
            '.carousel .slide img':{
                width: 'auto',
            },
            '.carousel .slide img.background':{
                width: '100%',
            },
        },
        root: {
            width: '100vw',
            position: 'relative',
            '& > $image': {
                filter: 'brightness(.6)',
                '&.hide': {
                    transition: 'filter 1s ease-in .75s',
                    filter: 'brightness(40)',
//                    filter: 'brightness(1.2) contrast(200%)',
                }
            },
        },
        image: {
            width: '100%',
            height: '100%',
            maxHeight: 'calc(100vh - 8rem)',
            objectFit: 'cover',
            objectPosition: 'top',
        },
        content: {
            position: 'absolute',
            top: 0, left: 0, right: 0, bottom: 0,
            zIndex: 100,
            overflow: 'hidden',
            fontSize: '1.5rem',
            [theme.breakpoints.down('sm')]:{
                fontSize: '1.25rem',
            },
            [theme.breakpoints.down('xs')]:{
                fontSize: '1rem',
            },
            [theme.breakpoints.down('xxs')]:{
                fontSize: '.75rem',
            },
        },
    }
};

const ImageSlider = ({children, active, fluid, minimalize, classes}) => {
    return (
        <section className={classes.root}>
            <div className={classes.content + (active? ' selected': '')}>
                {children}
            </div>
            <img
                src={fluid.src}
                srcSet={fluid.srcSet}
                sizes={fluid.sizes}
                className={classes.image + ' background' + (minimalize? ' hide': '')}
            />
        </section>
    );
};

export default injectSheet(styles)(ImageSlider);