/*
[theme.breakpoints.down('xs')]:{
    width: 'calc((100% - 2*24px)',

@media (max-width:959.95px) {
        width: calc(100% - 32px);
*/

const createResponsiveStyle = (name, theme) => ({
    [name]: {
        width: theme.breakpoints.getWidth('xxxl', 8),
        maxWidth: "1600px"
    },
    [theme.breakpoints.down('md')]:{
        [name]: {
            width: theme.breakpoints.getWidth('md', 8),
        }
    },
    [theme.breakpoints.down('sm')]:{
        [name]: {
            width: theme.breakpoints.getWidth('sm'),
        }
    },
    [theme.breakpoints.down('xs')]:{
        [name]: {
            width: theme.breakpoints.getWidth('xs'),
        }
    },
    [theme.breakpoints.down('xxs')]:{
        [name]: {
            width: theme.breakpoints.getWidth('xxs'),
        }
    },
});

export default createResponsiveStyle;