import React from 'react';
import injectSheet from 'react-jss';
import Link from "gatsby-link";

const styles = theme => {
    let borderColor = theme.palette.secondary.dark;
    let transition = '';//'color .25s ease-in, background .5s ease-in';

    return {
        root: {
            fontSize: '1.5rem',
            lineHeight: '1.2em',

            display: 'flex',
            position: 'relative',
            left: '-2px',
            '&:hover':{
                '& $date':{
                    color: theme.palette.secondary.light,
                    borderColor: theme.palette.secondary.light,
                },
                '& $content':{
                    background: theme.palette.secondary.light,
                    color: theme.palette.secondary.dark,
                    padding: '.25em .75em',
                    width: '300%',
                    transform: 'scaleX(1)',
                },
            },
        },
        date: {
            transition,

            position: 'relative',
//            borderLeftWidth: 0,

            fontSize: '2em',
            lineHeight: '1.1em',
            fontWeight: 600,
            width: '100%',
//            height: '5.5em',
            background: theme.palette.secondary.dark,
            background: theme.palette.background,
            color: theme.palette.secondary.dark,

            flex: '0 0 auto',
        },
        content: {

            flex: '1 1 auto',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
            height: '11em',

            color: theme.palette.secondary.light,
            background: theme.palette.secondary.dark,

            transform: 'scaleX(0)',
//                    transform: 'scaleX(1)',
            transformOrigin: 'left',
            transition: 'transform .5s ease-in',
            overflow: 'hidden',
            width: '290%',
//                    width: 0,
            padding: 0,
            position: 'absolute',
            left: '110%',

        },
    };
};

const RoadToHeader = ({image, link, children,classes}) => {
    return (
        <Link className={classes.root} to={link}>
            <span className={classes.date}>{image}</span>
            <span className={classes.content}>
                {children}
            </span>
        </Link>
    )
};

export default injectSheet(styles)(RoadToHeader);