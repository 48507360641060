import React from 'react'
import injectSheet from 'react-jss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons/faBars';
import SocialMediaIcon, {MediaType} from '../SocialMediaIcon';
import Link from '../Link';

const styles = theme => {
    return ({
        root: {
            height: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            '& ul': {
                padding: 0,
                margin: 0,
                listStyle: 'none',
            },
            '& li': {
                margin: 0,
            },
        },
        socialMedia: {
            display: 'flex',
            '& a':{
                color: theme.palette.primary.dark,
            },
        },
        menu: {
            display: 'flex',
            '& a': {
                display: 'block',
                textTransform: 'uppercase',
                marginLeft: '0.25rem',
                padding: '0.5rem 0.8125rem',
                borderStyle: 'solid',
                borderWidth: 1,
                borderColor: 'transparent',
                borderRadius: 4,
                '&:hover, &.active': {
                    borderColor: theme.palette.secondary.dark,
                },
            },
        },
        menuIcon: {
            display: "none",
            marginLeft: 16,
            color: theme.palette.primary.dark,
        },
        title: {
            flex: '1 1 auto',
            paddingLeft: '3rem',

            textTransform: 'uppercase',
            display: 'flex',
            justifyContent: 'center',
        },
        [theme.breakpoints.down('sm')]:{
            socialMedia: {
                display: 'none',
            },
        },
        [theme.breakpoints.down('xs')]:{
            menuWrapper: {
                position: 'relative',
                order: 10,
                transition: '',
                '&:hover $menu':{
                    maxHeight: '80vh',
                    border: '1px solid '+theme.palette.primary.dark,
                    '& a':{
                      margin: 0,
                        '&:hover': {
                            background: theme.palette.secondary.dark,
                            color: theme.palette.secondary.light,
                        }
                    },
                },
            },
            menu: {
                display: 'flex',
                flexDirection: 'column',
                zIndex: 1200,
                position: 'absolute',
                right: 0,
                top: 0,
                background: theme.palette.background,
                maxHeight:'0vh',
                overflowY: 'scroll',
                transition: 'max-height 250ms ease',
            },
            menuIcon: {
                display: "flex",
            },
            socialMedia: {
                display: 'flex',
            },
        },
        [theme.breakpoints.down('xxs')]:{
            socialMedia: {
                display: 'none',
            },
        },
    });
};

const Navigation = ({ classes, siteTitle, location }) => {
    let title;
    if(siteTitle !== null){
        title = (
            <div className={classes.title}>{siteTitle}</div>
        );
    }
    return (
        <nav className={classes.root}>
            {title}
            <div className={classes.menuWrapper}>
                <ul className={classes.menu}>
                    {
                        ['Programm', 'Bilder', 'News', 'Partner'].map(menuItem => (
                            <li key={menuItem}>
                                <Link to={'/'+menuItem.toLowerCase()+'/'} activeClassName="active" exact={false}>{menuItem}</Link>
                            </li>
                        ))
                    }
                </ul>
                <a className={classes.menuIcon}>
                    <FontAwesomeIcon icon={faBars}/>
                </a>
            </div>
            <ul className={classes.socialMedia}>
                <li>
                    <SocialMediaIcon href="https://www.facebook.com/SLAMDeutschsprachigePoetrySlamMeisterschaft" type={MediaType.FACEBOOK} />
                </li>
                <li>
                    <SocialMediaIcon href="https://twitter.com/Slam_2018" type={MediaType.TWITTER} />
                </li>
                <li>
                    <SocialMediaIcon href="https://www.instagram.com/slam2018/?ref=badge" type={MediaType.INSTAGRAM} />
                </li>
            </ul>
        </nav>
    );
};

export default injectSheet(styles)(Navigation);
