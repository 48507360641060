import {Carousel} from 'react-responsive-carousel'

class MyCarousel extends Carousel{
    destroyAutoPlay() {
        console.log(this.timer);
        clearTimeout(this.timer);
        console.log(this.timer);
        return super.destroyAutoPlay();
    }
}

export default MyCarousel;