import React from 'react'
import injectSheet from 'react-jss';
import logo from '../../assets/logo/slam2018_tuerkis_nob_bg.svg';
import {Link} from 'gatsby'

const styles = theme => {
    const barHeight = 4.125;
    const width = {
        default: 6.25,
        xl: 5.25,
        md: 4.75,
        sm: 3,
    };

    const getStyleSection = (breakpoint) => ({
        [theme.breakpoints.down(breakpoint)]:{
            logo: {
                top: barHeight - width[breakpoint] / 2 + 'rem',
                '& img': {
                    width: width[breakpoint] + 'rem',
                },
            }
        },
    });

    return ({
        logo: {
            position: 'absolute',
            zIndex: 1300,
            left: 24,
            top: barHeight-width.default/2 +'rem',
            '& img': {
                width: width.default+'rem',
            },
        },
        ...getStyleSection('xl'),
        ...getStyleSection('md'),
        [theme.breakpoints.down('sm')]:{
            logo: {
                top: (barHeight - width.sm) / 2 + 'rem',
                '& img': {
                    width: width.sm + 'rem',
                },
            }
        },
        [theme.breakpoints.down('xs')]: {
            logo: {
                left: 16,
            },
        },
    });
};

const Logo = ({ classes, siteTitle }) => (
    <Link to="/" className={classes.logo}>
        <img src={logo} />
    </Link>
);

export default injectSheet(styles)(Logo);