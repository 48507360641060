import React from "react";
import injectSheet from 'react-jss';

import {Link} from 'gatsby';

const styles = theme => {
    let color = theme.palette.primary.dark;
    color = theme.palette.backgroundSecondary;
    return {
        root: {
            color: color,
            position: 'absolute',
            left: "15%",
            top: '30%',
            display: 'flex',
            flexDirection: 'column',
            fontSize: '.75em',
            [theme.breakpoints.down('md')]:{
                left: "10%",
                right: "20%",
            },
            [theme.breakpoints.down('sm')]:{
                left: "5%",
            },
            [theme.breakpoints.down('xs')]:{
                right: "10%",
            },
        },
        title: {
            borderLeft: '2px solid '+ color,
            alignSelf: 'flex-start',
            '&::before':{
                content: '\'\'',
                display: 'block',
                borderTop: '2px solid '+color,
            },
            '&::after':{
                content: '\'\'',
                display: 'block',
                width: '150%',
                borderBottom: '2px solid '+color,
            },
            textAlign: 'left',
            '& h1':{
                margin: '.4em 0 .4em .4em',
                fontSize: '3em',
                textTransform: 'uppercase',
                opacity: 0,
                transition: 'opacity 1s ease-in',
//                transform: "translateY(-100%)",
//                transition: 'transform .75s ease-in',
            },
            transition: 'opacity .5s ease-in',
            opacity: 1,
            '&.hide': {
                opacity: 0,
            },
            '&.selected h1': {
                opacity: 1,
//                transform: 'translateY(0)',
            },
            marginBottom: '2em',
        },
        content: {
            display: 'flex',
            '& > div':{
                borderLeft: '2px solid '+color,
                marginLeft: '1rem',
                paddingLeft: '1rem',
                '&:first-child': {
                    padding: 0,
                    margin: 0,
                    borderWidth: 0,
                },

            },
            '& span': {
                display: 'inline-block',
                opacity: 0,
                transform: "translateX(-250px)",
                transition: 'transform .5s ease-in, opacity 0s ease-in',
                lineHeight: 1.1,
            },
            opacity: 1,
            transition: 'opacity .5s ease-in',
            '&.hide': {
                opacity: 0,
                '& span': {
                    transform: 'translateX(0)',
                },
            },
            '&.selected span': {
                opacity: 1,
                transform: 'translateX(0)',
            },
            [theme.breakpoints.down('xs')]:{
                fontSize: '1.25em',
            },
        },
        ticket: {
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
            alignSelf: 'flex-start',
            marginTop: '2em',
            opacity: 1,
            transition: 'opacity .5s ease-in',
            '&.hide': {
                opacity: 0,
            },
            '& a.button':{
                fontSize: '1.3em',
                lineHeight: 1,
                color: color,
                borderColor: color,
                background: theme.palette.background,
                borderWidth: '.1em',
                borderRadius: 4,
                textAlign: 'left',
                '&:hover': {
                    color: theme.palette.background,
                    background: color,
                },
            }
        },
    }
};

const SlideTextContent = ({title, text, active, hide=false, classes}) => {
    let delay = text.length * 200;
    let additionalClassname = (active? ' selected': '');
    additionalClassname += (hide? ' hide': '');
    return (
        <>
        <div className={classes.root}>
            <div className={classes.title + additionalClassname}>
                <h1>{title}</h1>
            </div>
            <div className={classes.content + additionalClassname}>
                {
                    text.map((text, index) => {
                        delay -= 200;
                        return (
                            <div>
                                <span style={{transitionDelay: delay+'ms'}}>{text}</span>
                            </div>
                        )
                    })
                }
            </div>
            <div className={classes.ticket + additionalClassname}>
                <Link to={'/tickets'} className="button">Jetzt Ticket<br/>sichern</Link>
            </div>
        </div>
        </>
    );
};

export default injectSheet(styles)(SlideTextContent);