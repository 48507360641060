import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import injectSheet from 'react-jss';
import Obfuscate from 'react-obfuscate';

import Header from '../components/header';
import './layout.css';
import 'typeface-oswald';
import createResponsiveStyles from "../utils/createResponsiveStyles";

const styles = theme => {
    const container = createResponsiveStyles('.container', theme);
    container['.container'].marginLeft = 'auto';
    container['.container'].marginRight = 'auto';

    let fontSize = 18;
    container.html = {
        fontSize,
    };
    const values = Object.keys(theme.breakpoints.values).reverse();
    for(let i=0; i<values.length; i++) {
        if(i%3 === 0){
            fontSize--;
            container.html[theme.breakpoints.down(values[i])] = {
                fontSize,

            };
        }
    }

    return {
        '@global': {
            body: {
                color: theme.palette.text.primary,
                background: theme.palette.background,
            },
            a: {
                textDecoration: 'none',
                color: theme.palette.secondary.dark,
                '&.button': {
                    cursor: 'pointer',
                    textTransform: 'uppercase',
                    display: 'block',
                    marginLeft: '0.25rem',
                    padding: '0.25rem 1rem',
                    border: '1px solid ' + theme.palette.secondary.dark,
                    borderRadius: 4,
                    '&:hover': {
                        background: theme.palette.secondary.dark,
                        color: theme.palette.secondary.light,
                    },
                },
            },
            main: {
                width: '100%',
                flex: '1 1 auto',
                background: theme.palette.background,
                '&.invert': {
                    background: theme.palette.primary.dark,
                }
            },
            '.invert': {
                color: theme.palette.background,
            },

            ...container
        },
        root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            '&.overflow':{
                height: '100vh',
                overflow: 'hidden',
            }
        },
        footer: {
            position: 'relative',
            flex: '0 0 4.125rem',
            background: theme.palette.background,
            width: "100%",
            padding: '2rem',
            display: 'flex',
            justifyContent: 'space-between',
            '&::before':{
                content: '\'\'',
                position: 'absolute',
                top: '1rem',
                left: 0,
                right: 0,
                borderTop: '2px solid '+theme.palette.primary.dark,
            },
            '& address':{
                //fontSize: '1.3em',
                '& > *': {
                    '&:last-child::after':{
                        display: 'none',
                    },
                    '&::after':{
                        content: '\', \'',
                        display: 'inline',
                    }
                }
            },
            [theme.breakpoints.down('sm')]: {
                flexWrap: 'wrap',
                '& > *:first-child':{
                    width: '100%',
                },
            },
        },
        header: {
            flex: '0 0 auto',
            width: "100%",
            '&.placeholder::after':{
                content: '\'\'',
                display: 'block',
                height: '4.125rem',
            }
        },
        title: {
            display: 'none',
        },
        pageHeader: {
            fontSize: '2rem',
            marginTop: '4.125rem',
            marginBottom: '4rem',
            textTransform: 'capitalize',
            '& *':{
                padding: '.15em .5em',
                position: 'relative',
                display: 'inline-block',
                borderLeft: '0.1rem solid ' + theme.palette.primary.dark,
                '&::before':{
                    content: '\'\'',
                    display: 'block',
                    borderTop: '0.1rem solid ' + theme.palette.primary.dark,
                    width: '105%',
                    position: 'absolute',
                    top: 0, left: 0
                },
                '&::after':{
                    content: '\'\'',
                    display: 'block',
                    borderBottom: '0.1rem solid ' + theme.palette.primary.dark,
                    width: '125%',
                    position: 'absolute',
                    left: 0, bottom: 0,
                },
            },
            '&.invert *':{
                borderColor: theme.palette.background,
                '&::before':{
                    borderColor: theme.palette.background,
                },
                '&::after':{
                    borderColor: theme.palette.background,
                },
            }
        },
        [theme.breakpoints.down('xs')]:{
            title: {
                display: 'inherit',
            },
            pageHeader: {
                display: 'none',
            },
        },
    }
};

//TODO: make SEO
const Layout = ({ children, pageTitle="", overflow=false, hideHeader=false, pageHeader=false, barTitle=true, solidBar=true, placeholder=true, invert=false, classes}) => {

    if(!pageTitle.startsWith("")){
        let map = new Map();
        map.keys();
        map.values();
        new Set().values();
    }

    let title = barTitle === true? (<h1 className={classes.title}>{pageTitle}</h1>): null;
    let contentHeader = pageHeader === true? (
        <header className={classes.pageHeader + ' container' + (invert? ' invert': '')}>
            <h2>{pageTitle}</h2>
        </header>
    ): null;

    pageTitle = pageTitle?
        pageTitle + ' | SLAM 2018':
        'SLAM 2018 | Die deutschsprachigen Meisterschaften im Poetry Slam';

    return (
        <div id="header" className={classes.root + (overflow? ' overflow': '')}>
            <Helmet
                title={pageTitle}
                meta={[
                    { name: 'description', content: 'Sample' },
                    { name: 'keywords', content: 'sample, something' },
                ]}
            >
                <html lang="de" />
            </Helmet>
            <header className={classes.header + ' observableTarget' + (placeholder? ' placeholder': '')}>
                <Header siteTitle={title} solid={solidBar} hide={hideHeader}/>
            </header>
            <main className={invert? 'invert': ''}>
                {contentHeader}
                {children}
            </main>
            <footer className={classes.footer}>
                <span>Verein Slam 2018 Zürich</span>
                <address>
                    <span>Stückelbergstrasse 4</span>
                    <span>9000 St. Gallen</span>
                </address>
                <Obfuscate email="info@slam2018.ch" />
            </footer>
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    pageTitle: PropTypes.string,
    pageHeader: PropTypes.bool,
    barTitle: PropTypes.bool,
    solidBar: PropTypes.bool,
    placeholder: PropTypes.bool,
    invert: PropTypes.bool,
};

export default injectSheet(styles)(Layout);
