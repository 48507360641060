import React from 'react'
import PropTypes from 'prop-types'
import Box from "../Box";
import OverviewContent, {SmallOverviewContainer} from "./OverviewContent";

const Overview = ({header, content, small=false, controller, postHeader, className}) => {
    if(content) {
        const ContentElement = small? SmallOverviewContainer: OverviewContent;
        content = content.map( ({image, children, ...data}) => {
                return (
                    <ContentElement
                        img={image}
                        {...data}
                    >
                        {children}
                    </ContentElement>
                );
            }
        );
    }
    return (
        <Box header={header} controller={controller} className={className} postHeader={postHeader}>
            <div style={{paddingTop: 24, display: 'flex', flexFlow: 'row wrap', width: '100%'}}>
                {content}
            </div>
        </Box>
    );
};

Overview.propTypes = {
    header: PropTypes.string.isRequired,
    content: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        image: PropTypes.element.isRequired,
        to: PropTypes.string.isRequired,
        children: PropTypes.element.isRequired,
    })).isRequired,
};

export default Overview