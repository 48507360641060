import React from 'react'
import injectSheet from 'react-jss';
import { graphql, Link } from "gatsby"

import 'dayjs/locale/de';
import dayjs from "dayjs";

import Layout from '../containers/layout'
import NewsOverview from '../containers/NewsOverview'
import SmallBox from "../components/SmallBox";
import ResponsiveSlider from "../components/carousel/ResponsiveSlider";
import ImageSlide from "../components/carousel/ImageSlide";
import SlideContent from "../components/carousel/SlideContent";
import SlideTextContent from "../components/carousel/SlideTextContent";
import RoadToSection from "../containers/RoadToSection";
import roadToLogo from '../assets/logo/Road-To-Shows-Logo.png';
import RoadToHeader from "../components/RoadToHeader";


import '../utils/intersection-observer';
import Observer from '@researchgate/react-intersection-observer';
import Video from "../components/Video";
import BoxContent from "../components/box/BoxContent";


const styles = theme => {
    return {
        root: {
            position: 'relative',
        },
        infoContainer: {
            display: 'flex',
            '& > *': {
                width: '50%',
            },
            [theme.breakpoints.down('md')]: {
                '& > *': {
                    width: '100%',
                    marginLeft:0,
                    marginRight:0,
                },
                flexWrap: 'wrap',
            }
        },
    };
};

class IndexPage extends React.Component{
    state = {
        solid: false,
        loaded: false,
        selectedSlide: 0,
        play: false,
        showVideo: false,
    };

    constructor(props){
        super(props);

        this.onChangeSlide = this.onChangeSlide.bind(this);
        this.handleIntersect = this.handleIntersect.bind(this);
        this.onPlayClicked = this.onPlayClicked.bind(this);
        this.onEnded = this.onEnded.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
    }

    handleIntersect(event){
        this.setState({
            solid: !event.isIntersecting
        });
    }

    componentDidMount(){
        let that=this;
        //TODO: wait until image is loaded
        window.setTimeout(() => {
            that.setState({loaded: true});
        }, 1000);
    }

    componentWillUnmount() {
        window.removeEventListener("keyup", this.onKeyUp);
    }

    onKeyUp(event) {
        if(event.keyCode===27){
            this.onEnded();
        }
    }

    onChangeSlide(index, element) {
        window.removeEventListener("keyup", this.onKeyUp);
        this.setState({
            showVideo: false,
            play: false,
            selectedSlide: index,
        });
    }

    onPlayClicked(){
        window.addEventListener("keyup", this.onKeyUp);
        this.setState({
            play: true,
        });
        window.setTimeout(() =>{
            this.setState({
                showVideo: true,
            });
        }, 1500)
    }

    onEnded(){
        window.removeEventListener("keyup", this.onKeyUp);
        this.setState({
            showVideo: false,
            play: false,
        });
    }

    changePlayState(state){
        this.setState({
            play: state,
        });
    }

    renderSlide(slide, data, active) {
        let content = [];
        if(data.id===1) {
            content.push((
                <SlideContent
                    active={!this.state.play  && active}
                    hide={this.state.play}
                />
            ));
        }
        if(data.text){
            content.push((
                <SlideTextContent
                    active={active}
                    hide={this.state.play}
                    title='Slam 2018'
                    text={data.text}
                />
            ));
        }
        if(data.url){
            content.push((
                <Video
                    url={data.url}
                    onPlayClicked={this.onPlayClicked}
                    onEnded={this.onEnded}
                    onPlay={() => this.changePlayState(true)}
                    onPause={() => this.changePlayState(false)}
                    showVideo={active && this.state.showVideo}
                    play={active && this.state.play}
                    showControl={!active || !(this.state.play || this.state.showVideo)}
                    width="100%"
                />
            ));
        }
        return (
            <ImageSlide
                key={data.id}
                fluid={slide.childImageSharp.fluid}
                active={active}
                minimalize={this.state.play}
            >
                {content}
            </ImageSlide>
        );
    }

    render() {
        const classes = this.props.classes;

        let slideData = [
            {
                id: 1,
                url: "https://www.youtube.com/watch?v=amIyxCSfF3g"
            }, {
                id: 2,
                text:[
                    '200 Poetry SlammerInnen',
                    '20 Poetry Slams',
                    'Einzel- und Teamwettbewerb'
                ]
            }, {
                id: 3,
                text: [
                    'SA 10. November 2018',
                    'Finale im Hallenstadion',
                    '4\'500 ZuschauerInnen'
                ]
            },
        ];

        return (
            <div>
                <Layout
                    hideHeader={this.state.play}
                    solidBar={this.state.solid}
                    placeholder={false}
                    barTitle={false}
                >
                    <Observer
                        onChange={this.handleIntersect}
                    >
                        <ResponsiveSlider
                            autoPlay={!(this.state.play || this.state.showVideo)}
                            showIndicators={!this.state.play}
                            onChange={this.onChangeSlide}
                            selectedItem={this.state.selectedSlide}
                        >
                            { this.props.data.sildeFiles.edges.map(({slide}, index) =>  this.renderSlide(slide, slideData[index], this.state.loaded && index===this.state.selectedSlide)) }
                        </ResponsiveSlider>
                    </Observer>
                    <div className={classes.root + ' container'}>
                        <div className={classes.infoContainer}>
                            <SmallBox header="Bildergalerie">
                                <BoxContent linkTo={'/bilder/'} linkText={(<>Zur Galerie</>)} invert={true}>
                                    Bilder zum Festival
                                </BoxContent>
                            </SmallBox>
                            <SmallBox header="Resultate">
                                <BoxContent linkTo={'/programm/resultate/'} linkText={(<>Weiter</>)}>
                                    Alle Resultate zum Wettbewerb des 5-tägigen Poetry-Slam-Festivals
                                </BoxContent>
                            </SmallBox>
                        </div>

                        <NewsOverview data={this.props.data.posts} filterable={false}/>
                    </div>
                </Layout>
            </div>
        );
    };
}

export default injectSheet(styles)(IndexPage);

export const query = graphql`
    query IndexPageQuery {
        slides: allWordpressWpSlides{
            edges {
                slide: node {
                    title
                    featured_media{
                        localFile {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid_tracedSVG
                                }
                            }
                        }
                    }
                }
            }
        }
        sildeFiles: allFile (
            filter: {relativePath: {regex: "/carousel\/[1-9]/"}},
            sort: {fields: [relativePath]}
        ){
            edges{
                slide: node{
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
            }
        }
        posts: allWordpressPost(
            filter: { status: {eq: "publish"} }
            limit: 3
        ) {
            edges {
                node {
                    id
                    slug
                    title
                    excerpt
                    date
                    modified
                    template
                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 2048, grayscale: true) {
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    sizes
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;