import React from 'react';
import {connect} from "react-redux";
import injectSheet from 'react-jss';
import { StaticQuery, graphql } from "gatsby"

import {setNewsFilter} from "../actions/index";

const styles = theme => {
    return {
        root: {
            display:'flex',
            flexWrap: 'wrap',
//            color: theme.palette.primary.dark,
            color: theme.palette.backgroundSecondary,
        },
        group: {
            display:'flex',
            minWidth: '9em',
            marginRight: '2em',
            alignItems: 'center',
        },
        label: {
            marginLeft: '1em',
        },
        field: {
//            '-webkit-appearance': 'none',
//            border: '1px solid '+theme.palette.primary.dark,
//            width: '1em',
//            height: '1em',
            '&:checked::after':{
//                content: '"\\2713"',
            },
        },
    }
};

const getSelection = formRef => {
    let filter = [];
    if(formRef.current && formRef.current.elements){
        let elements = formRef.current.elements;
        for(let i=0; i<elements.length; i++){
            if(elements[i].checked){
                filter.push(elements[i].value);
            }
        }
    }
    return filter;
};

const NewsFilter = ({categories, changeFilter, classes}) => {
    const formRef = React.createRef();
    return (
        <form className={classes.root} ref={formRef}>
            {
                categories.map(category =>
                    category.count>0 ? (
                        <label className={classes.group}>
                            <input
                                type="checkbox"
                                className={classes.field}
                                value={category.name}
                                checked={category.selected? 'checked': ''}
                                onChange={() => changeFilter(getSelection(formRef))}
                            />
                            <span className={classes.label}>{category.name}</span>
                        </label>
                    ): null
                )
            }
        </form>
    );
};


const mapStateToProps = (state, ownProps) => {
    return {
        categories: ownProps.categories.map(category => {
            let filter = state.filter.find(f => f===category.name);
            return {
                ...category,
                selected: filter!=null
            };
        })
    }
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    changeFilter: filter => dispatch(setNewsFilter(filter))
});

const CheckedNewsFilter = connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectSheet(styles)(NewsFilter));


export default () => (
    <StaticQuery
        query={graphql`
    query {
        categories: allWordpressCategory{
            edges{
                category: node{
                    name
                    count
                }
            }
        }
    }
`
        }
        render={data => (
            <CheckedNewsFilter categories={data.categories.edges.map(({category}) => category)}/>
        )}
    />
);


