import React from 'react'
import Img from 'gatsby-image'
import Overview from "../components/overview";
import injectSheet from 'react-jss';

import 'dayjs/locale/de';
import dayjs from "dayjs";
import NewsFilter from "../components/NewsFilter";

const styles = theme => ({
    root: {
        borderBottom: '0 solid '+theme.palette.primary.dark,
        transition: 'max-height .25s ease-in, padding .25s ease-in, border 0s ease-in .25s',
        maxHeight: 0,
        overflow: 'hidden',
        padding: '0 1em',
        '&.open':{
            maxHeight: '20rem',
            borderBottomWidth: 2,
            transition: 'max-height .25s ease-in, padding .25s ease-in',
            padding: '1em',
            overflow: 'auto',
        }
    },
    controller: {
        display: 'flex',
        flex: '1 1',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        fontWeight: 500,
        background: theme.palette.primary.dark,
        color: theme.palette.background,
        '&:hover':{
            color: theme.palette.primary.dark,
            background: theme.palette.background,
        },
    },
});

const NewsOverview = ({data, small=false, filterable=true, filterOpen=false, toogleFilter,  className, classes}) => {
    let posts = [];
    if(data) {
        posts = data.edges.map( post => {
                let image = null;
                if(post.node.featured_media.localFile) {
                    let fluid = post.node.featured_media.localFile.childImageSharp.fluid;
                    image = (<Img fluid={fluid}/>);
                }
                return {
                    key: post.node.id,
                    date: dayjs(post.node.date).locale('de').format('D. MMM YYYY'),
                    image,
                    to: "/news/"+post.node.slug+"/",
                    children: (
                        <>
                            <h4 dangerouslySetInnerHTML={ { __html: ( post.node.title ) } } />
                            <div dangerouslySetInnerHTML={ { __html: ( post.node.excerpt ) } } />
                        </>
                    )
                };
            }
        );
    }
    let postHeader;
    let controller;
    if(filterable){
        postHeader = (
            <div className={classes.root + (filterOpen? ' open': '')}>
                <NewsFilter />
            </div>
        );

        controller = (<span onClick={() => toogleFilter()} className={classes.controller}>Filter</span>);
    }
    return (
        <div className={className}>
            <Overview header="News" content={posts} small={small} controller={controller} postHeader={postHeader}/>
        </div>
    );
};

export default injectSheet(styles)(NewsOverview);