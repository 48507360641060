import React from "react";
import injectSheet from 'react-jss';
import ReactPlayer from 'react-player'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlayCircle} from '@fortawesome/free-regular-svg-icons/faPlayCircle';

const styles = theme => {
    let radius = 6;
    return {
        playButtonWrapper: {
            display: "flex",
            height: '80%',
            justifyContent: "center",
            alignItems: "center",
            opacity: 1,
            transition: 'opacity .5s ease',
            '&.hide': {
                opacity: 0,
            },
        },
        playButton: {
            position: 'relative',
            zIndex: 100,
            fontSize: '5em',
            color: theme.palette.backgroundSecondary,
//            color: theme.palette.secondary.light,
            '&:hover': {
                cursor: 'pointer',
                color: theme.palette.background,
            },
        },
        player: {
            position: 'absolute',
            top: 0, left: 0, right: 0, bottom: 0,
            background: 'black',
            zIndex: 1500,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& > *': {
                width: '100%'
            }
        },
    }
};

const Video = ({url, onPlayClicked, width, onEnded, onPlay, onPause, showControl=true, showVideo=false, classes}) => {
    let player;
    if(showVideo){
        let options = {};
        if(width){
            options.width = width;
        }
        player = (
            <div className={classes.player}>
                <ReactPlayer
                    url={url}
                    height='100%'
                    playing={true}
                    onPlay={onPlay}
                    onPause={onPause}
                    onEnded={onEnded}
                    config={{
                        youtube: {
                            playerVars: {controls: 1, color:'white'}
                        }
                    }}
                    {...options}
                />
            </div>
        );
    }
    return (
        <>
        <div className={classes.playButtonWrapper + (!showControl ? ' hide': '')}>
            <a className={classes.playButton}
               onMouseDown={ e => e.stopPropagation() }
               onTouchStart={ e => e.stopPropagation() }
               onTouchEnd={ e => e.stopPropagation() }
               onClick={onPlayClicked}>
                <FontAwesomeIcon icon={faPlayCircle}/>
            </a>
        </div>
        {player}
        </>
    );
};

export default injectSheet(styles)(Video);